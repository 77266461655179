// const BASE_URL = 'https://api.farmakom.it'
const BASE_URL = 'https://api.farmakom.io'
// const BASE_URL = "https://api.staging.farmakom.it";
// const BASE_URL = "http://vps725543.ovh.net:8087";
// const BASE_URL = 'http://localhost:8087'
// const BASE_URL = 'http://localhost:8081'

export const API_URL = BASE_URL + '/v1/backend'
export const API_URL_FRONTEND = BASE_URL + '/v1/frontend'
export const API_URL_EDITOR = BASE_URL + '/v1/editor'
export const API_URL_SALES = BASE_URL + '/v1/sales'

export const URL_EDITOR = 'https://editor.farmakom.it'
// export const URL_EDITOR = 'http://localhost:3001'

export const URL_ADMIN = 'https://admin.farmakom.it';
// export const URL_ADMIN = 'https://admin.farmakom.io'
