import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from '../AuthContext'
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuth, trying }) => (
      <React.Fragment>
        {trying ? null : <Route render={props => (isAuth ? <Component {...props} /> : <Redirect to="/login" />)} {...rest} />}
      </React.Fragment>
    )}
  </AuthConsumer>
)
export default ProtectedRoute
