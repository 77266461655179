import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable'
import numeral from 'numeral'
import axios from 'axios'

import { AuthProvider } from './components/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'

import './App.scss'

// INITIALIZE NUMERAL LIB
numeral.register('locale', 'it', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mila',
    million: 'mil',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function(number) {
    return 'º'
  },
  currency: {
    symbol: '€ ',
  },
})
numeral.locale('it')


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading,
})

// Pages
const Login = Loadable({
  loader: () => import('./views/Login'),
  loading,
})

class App extends Component {
  render() {
    return (
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </AuthProvider>
      </Router>
    )
  }
}

export default App
