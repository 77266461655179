import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../config'

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
  state = { isAuth: false, trying: true }

  async componentDidMount() {
    // If I have a token in LocalStorage
    // try a get something:
    // if OK, set authenticated
    // else remove the token (to avoid a redirect loop)

    const token = localStorage.getItem('farmakom_sm_token')

    if (token) {
      try {
        await axios.get(`${API_URL}/account/check-token-validity`, {
          headers: { Authorization: token },
        })
        this.setAuthenticated(token)
      } catch (error) {
        this.logout()
      }
    } else {
      this.setState({
        trying: false,
      })
    }
  }

  login = async (email, password) => {
    try {
      const result = await axios.post(`${API_URL}/account/login`, {
        email,
        password,
      })
      this.setAuthenticated(result.data.authToken)
      if (result.data.user.id) {
        localStorage.setItem('operatorId', result.data.user.id)

        localStorage.setItem('scope', result.data.role)
      }
      // REDIRECT
      this.props.history.push('/home')
    } catch (error) {
      return error
    }
  }

  logout = () => {
    this.setState({ isAuth: false, trying: false })
    localStorage.clear()
    this.props.history.push('/login')
  }

  setAuthenticated = (token) => {
    this.setState({ isAuth: true, trying: false })
    localStorage.setItem('farmakom_sm_token', token)
    // INITIALIZE AXIOS WITH AUTH TOKEN
    axios.defaults.headers.common['Authorization'] = token
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          trying: this.state.trying,
          login: this.login,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

AuthProvider = withRouter(AuthProvider)
const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
